<template>
  <v-container>
    <div v-if="authorizedAccess">
      <v-layout row wrap justify-space-between class="mx-1">
        <v-spacer></v-spacer>
        <v-flex xs12 sm6 md6 lg6 class="text-right">
          <v-btn
            :color="
              button.selected
                ? $store.state.secondaryColor
                : $store.state.primaryColor
            "
            outlined
            class="mx-1"
            small
            v-for="button in menu"
            :key="button.title"
            @click="goTo(button.route)"
          >
            <v-icon small left>{{ button.icon }}</v-icon>
            <span class="caption text-capitalize">{{ button.title }}</span>
          </v-btn>
        </v-flex>
      </v-layout>

      <router-view></router-view>
    </div>

    <div class="fill-height" v-else>
      <UnauthorizedAccess :user="user" />
    </div>
  </v-container>
</template>

<script>
import jwtdecode from "jwt-decode";
import UnauthorizedAccess from "@/components/mijini/UnauthorizedAccess";
export default {
  components: {
    UnauthorizedAccess,
  },
  data: () => ({
    authorizedAccess: false,
  }),

  created() {
    this.getUser();
    this.selectedChange();
  },
  computed: {
    menu() {
      return [
        {
          icon: "mdi-cash",
          title: this.$t("all sales"),
          route: "ViewSales",
          path: "",
          selected: false,
        },
        {
          icon: "mdi-receipt",
          title: this.$t("receipts"),
          route: "ViewReceipts",
          path: "receipts",
          selected: false,
        },
      ];
    },
  },

  watch: {
    $route() {
      this.selectedChange();
    },
  },

  methods: {
    getUser() {
      this.user = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );

      if (!this.user.accessLevel.isEmployee) this.authorizedAccess = true;
      else this.authorizedAccess = false;
    },

    goTo(route) {
      this.$router.push({ name: route });
    },
    selectedChange() {
      this.menu.forEach((val) => {
        val.selected = false;
      });
      var route = this.$route.path;
      //first item in array should match the correct Route name, the rest could be anything
      var submenu1 = ["ViewSales", "Receipts"];

      if (submenu1.includes(this.$route.name)) {
        this.menu[0].selected = true;
      } else {
        var i;
        var lang = localStorage.getItem("lang");
        //var lang = "en";
        if (lang == null) {
          i = this.menu.findIndex((x) => "/en/sales/" + x.path == route);
        } else {
          i = this.menu.findIndex(
            (x) => "/" + lang + "/sales/" + x.path == route
          );
        }
        if (i > 0) {
          this.menu[i].selected = true;
        }
      }
    },
  },
};
</script>

<style></style>
